type Props = {
  handleClick: () => void
}

export const HunburgerButton: React.FC<Props> = ({ handleClick }) => {
  return (
    <button className="global-header-navTrigger" onClick={handleClick}>
      <span className="global-header-navTrigger-bar"></span>
      <span className="global-header-navTrigger-label sr-only">メニュー</span>
    </button>
  )
}
