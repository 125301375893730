import { useContext, useEffect, useState } from "react"
import { globalStoreContext } from "../../store/GlobalStore"

export const useMyAvatarSrc = () => {
  const {
    globalStore: {
      user: { firebaseUserData, apiUserData },
    },
  } = useContext(globalStoreContext)

  const [iconSrc, setIconSrc] = useState("/img/user-icon.png")

  useEffect(() => {
    if (apiUserData?.icon_url) {
      setIconSrc(apiUserData?.icon_url)

      return
    }
  }, [apiUserData, firebaseUserData])

  return iconSrc
}
