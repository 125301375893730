import { useContext } from "react"
import "firebase/auth"
import { globalStoreContext } from "../../store/GlobalStore"

/** 匿名ログインの実行 */
export const logout = async () => {
  return
}

const useUser = () => {
  const {
    globalStore: {
      user: { apiUserData, firebaseUserData, clientState },
    },
  } = useContext(globalStoreContext)

  return {
    firebaseUser: firebaseUserData,
    apiUser: apiUserData,
    clientState,
    isMember: clientState === 2,
  }
}

export { useUser }
