import { useCallback, useContext, useMemo } from "react"

import { globalStoreContext } from "src/store/GlobalStore"

// hook
import { useAuth } from "src/components/hooks/useAuth"
import { useLoading } from "src/components/hooks/useLoading"
import { useJudgeDevice } from "src/components/hooks/useJudgeDevice"
/**
 * イラストデータを参照する
 */
const useHeaderManage = () => {
  const { logout } = useAuth()
  const { fullScreenLoading } = useLoading()
  const { globalDispatch, globalStore } = useContext(globalStoreContext)
  const { isLarge: isPc } = useJudgeDevice("md")

  /** グローバルヘッダの状態 */
  const globalPcHeaderMenuVisible = useMemo(
    () => globalStore.ui.globalHeader.visible,
    [globalStore.ui.globalHeader.visible],
  )

  const globalSpHeaderMenuVisible = useMemo(
    () => globalStore.ui.globalSpMenu.visible,
    [globalStore.ui.globalSpMenu.visible],
  )

  /** メニューのトグル */
  const handleClickMenuToggel = useCallback(() => {
    if (isPc) {
      globalDispatch({
        type: "ui/globalHeader/visible",
        payload: !globalPcHeaderMenuVisible,
      })
    } else {
      globalDispatch({
        type: "ui/globalSpMenu/visible",
        payload: !globalSpHeaderMenuVisible,
      })
    }
  }, [
    globalDispatch,
    globalPcHeaderMenuVisible,
    globalSpHeaderMenuVisible,
    isPc,
  ])

  // メニューを閉じる処理
  const handleMenuClose = useCallback(() => {
    if (isPc) {
      globalDispatch({
        type: "ui/globalHeader/visible",
        payload: false,
      })
    } else {
      globalDispatch({
        type: "ui/globalSpMenu/visible",
        payload: false,
      })
    }
  }, [globalDispatch, isPc])

  // PCメニューを閉じる処理
  const handlePcMenuClose = useCallback(() => {
    globalDispatch({
      type: "ui/globalHeader/visible",
      payload: false,
    })
  }, [globalDispatch])

  // SPメニューを閉じる処理
  const handleSpMenuClose = useCallback(() => {
    globalDispatch({
      type: "ui/globalSpMenu/visible",
      payload: false,
    })
  }, [globalDispatch])

  // ログアウト処理
  const handleLogout = useCallback(async () => {
    fullScreenLoading(true)
    await logout()
    fullScreenLoading(false)
    handleMenuClose()
  }, [fullScreenLoading, handleMenuClose, logout])

  return {
    globalPcHeaderMenuVisible,
    globalSpHeaderMenuVisible,
    handleClickMenuToggel,
    handleMenuClose,
    handlePcMenuClose,
    handleSpMenuClose,
    handleLogout,
  }
}

export { useHeaderManage }
