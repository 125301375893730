import Link from "next/link"
import { HeaderSearch } from "src/components/common/GlobalHeader/HeaderSearch"

import routes from "routes"

export const AuthList: React.FC = () => (
  <>
    <HeaderSearch isLoggedIn={false} />
    <ul className="global-header-authList">
      <li className="global-header-login">
        <Link href={routes.authSignIn()}>
          <a className="global-header-login-button" data-cy="login-button">
            <i className="fas fa-sign-in-alt global-header-login-button-icon" />
            <span>ログイン</span>
          </a>
        </Link>
      </li>
      <li className="global-header-regist">
        <Link href={routes.authSignUp()}>
          <a className="global-header-regist-button" data-cy="regist-button">
            <span>新規登録</span>
          </a>
        </Link>
      </li>
    </ul>
  </>
)
