import {
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  linkWithCredential,
} from "firebase/auth"

import { useCallback } from "react"
import { useTieupToast } from "./useTieupToast"
import { Auth as AuthMessage } from "src/config/messages"
import { useLoading } from "./useLoading"
import { useCommonConfirmDialog } from "./useCommonConfirmDialog"

const useAuth = () => {
  const auth = getAuth()
  const { addToastInfo, addToastError } = useTieupToast()
  const { fullScreenLoading } = useLoading()
  const { confirmDialog } = useCommonConfirmDialog()

  /** メールアカウントへ認証プロバイダをリンクする */
  const linkWithCredentialByEmail = async (email: string, password: string) => {
    const currentUser = auth.currentUser
    if (!currentUser) {
      // eslint-disable-next-line no-console
      console.log("currentUserが存在しません")

      return
    }
    const credential = EmailAuthProvider.credential(email, password)

    try {
      await linkWithCredential(currentUser, credential)
    } catch (error) {
      throw new Error(error)
    }
  }

  /** メールアドレスが登録済みかチェックする */
  const isSignedUpByEmail = async (email: string) => {
    try {
      // すでにpassword認証でemailが登録されている場合は["password"]という配列が返る
      const providers = await fetchSignInMethodsForEmail(auth, email)

      return (
        providers.findIndex(
          (p) => p === EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
        ) !== -1
      )
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  const logout = useCallback(() => {
    const func = async () => {
      try {
        fullScreenLoading(true)
        await auth.signOut()
        addToastInfo("ログアウトしました")
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        addToastError(AuthMessage.logoutError)
      } finally {
        fullScreenLoading(false)
      }
    }
    confirmDialog("ログアウトしますか？", "はい", "いいえ", func)
  }, [confirmDialog, fullScreenLoading, addToastInfo, addToastError, auth])

  return {
    auth,
    linkWithCredentialByEmail,
    isSignedUpByEmail,
    logout,
  }
}

export { useAuth }
