import Link from "next/link"
import TieupLogo from "../TieupLogo"

/**
 * たいあっぷロゴ
 */

type Props = {
  linkTo?: string
}

const Logo: React.FC<Props> = ({ linkTo = "/creator" }) => {
  return (
    <div className="global-header-logo">
      <Link href={linkTo}>
        <a>
          <TieupLogo />
        </a>
      </Link>
    </div>
  )
}

export default Logo
