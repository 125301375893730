import { useState } from "react"
import { useLifecycles } from "react-use"
import { debounce } from "lodash"

interface IBp {
  [key: string]: string
  xs: string
  sm: string
  md: string
  single: string
  lg: string
  xl: string
}

const bp: IBp = {
  xs: "375px",
  sm: "560px",
  md: "768px",
  single: "900px",
  lg: "960px",
  xl: "1200px",
}

const useJudgeDevice = (scale = "md") => {
  const [isLarge, setIsLarge] = useState<boolean>(false)

  const judgeSize = debounce(() => {
    window.matchMedia(`(min-width:${bp[scale]})`).matches
      ? setIsLarge(true)
      : setIsLarge(false)
  }, 100)

  useLifecycles(
    () => {
      judgeSize()
      window.addEventListener("resize", judgeSize)
    },
    () => window.removeEventListener("resize", judgeSize),
  )

  return { isLarge }
}

export { useJudgeDevice }
