/**
 * アバターボタン
 */

import TieupImg from "../TieupImg"
import { useMyAvatarSrc } from "src/components/hooks/useMyAvatarSrc"

type Props = {
  onClick: () => void
}

const AvatarButton: React.FC<Props> = ({ onClick }) => {
  const avatarSrc = useMyAvatarSrc()

  return (
    <button className="global-header-avatar-button" onClick={onClick}>
      <TieupImg src={avatarSrc} />
    </button>
  )
}
export default AvatarButton
