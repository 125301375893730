import { useCallback } from "react"
import { confirmAlert } from "react-confirm-alert"

const useCommonConfirmDialog = () => {
  /**
   * 共通の確認ダイアログ
   * @param message 確認テキスト
   * @param agreeLabel 「はい」など、処理を実行するボタンに付けるラベル
   * @param cancelLabel 「いいえ」など、処理を実行しないボタンに付けるラベル
   * @param func 実行したい関数
   */
  const confirmDialog = useCallback(
    (
      message: string,
      agreeLabel: string,
      cancelLabel: string,
      func: (() => Promise<void> | void) | null,
      optionText = "",
    ) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <>
              <div className="p-modal modal-primary js-modal">
                <div className="p-modal-inner">
                  <div className="p-modal-contents">
                    <div className="p-modal-contents-inner">
                      <button
                        className="c-close-button p-modal-close js-modal-close"
                        onClick={() => {
                          onClose()
                        }}
                      >
                        <span className="c-close-button-icon"></span>
                        <span className="c-close-button-label">閉じる</span>
                      </button>
                      <div className="modal-primary-body">
                        <div className="modal-primary-body-inner">
                          <p>{message}</p>
                        </div>
                        {optionText && (
                          <div style={{ marginTop: "10px", fontSize: "13px" }}>
                            {optionText}
                          </div>
                        )}
                      </div>
                      <div className="p-modal-footer">
                        <div className="p-modal-action -plate">
                          <div className="p-modal-action-col">
                            <button
                              className="p-modal-action-cancel c-button-secondary -outline -full"
                              onClick={() => {
                                onClose()
                              }}
                            >
                              {cancelLabel}
                            </button>
                          </div>
                          <div className="p-modal-action-col -plate">
                            <button
                              onClick={() => {
                                if (func) {
                                  func()
                                }

                                onClose()
                              }}
                              className="p-modal-action-submit c-button-secondary -full"
                            >
                              {agreeLabel}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-modal-overlay js-modal-overlay"></div>
            </>
          )
        },
      })
    },
    [],
  )

  return {
    confirmDialog,
  }
}

export { useCommonConfirmDialog }
