/** メッセージ一覧 */

export const Auth = {
  error: {
    email: "正しいメールアドレスを入力してください",
    password: "パスワードに誤りがあります",
  },
  sendMail: "確認メールを送信しました",
  sendRenewEmail: "新しいメールアドレスに確認メールを送信いたしました",
  passwordReminder: "パスワード設定画面をご登録のメールアドレスに送信しました",
  passwordRenew: "ご登録のメールアドレスにパスワード変更フォームを送信しました",
  verifyEmail:
    "メールアドレスの確認が完了しました。新しいメールアドレスで再ログインしてください",
  logout: "ログアウトしました",
  logoutError: "ログアウトに失敗しました",
}

export const CreatorMyPage = {
  createNote: "新規メモを作成しました",
}

export const ApiErrorMessage = (key?: string) => {
  switch (key) {
    case "ALREADY_REGISTER_DISPLAY_NAME": {
      return "ユーザーIDが他で使用されています"
    }
    default: {
      return key
    }
  }
}

export const PageLeave = {
  alert: "このページを離れますか？ 行った変更が保存されない可能性があります。",
  alertIllustrator: "このページを離れますか？",
}

export const EditorMessage = {
  info: {
    successSave: "章を保存しました。",
    successPublish: "章を保存、公開しました。",
  },
  error: {
    common: "エラーが発生しました。時間をおいて、再度お試しください。",
    rubyNotSelect: "ルビを振るテキストを選択してください",
    dotNotSelect: "傍点を振るテキストを選択してください",
    selectStartTag: "挿絵挿入を開始する行を選んでください",
    canNotEditPlateTags:
      '小説家は、挿絵タグ（[image id="xxx"]と[/image]）と、それをまたぐ範囲で文章の編集はできません。',
    canNotSelectPlateTags:
      "挿絵タグは選択できません。もう一度やり直してください。",
    overwrappingPlateTags:
      "挿絵の範囲が重複しています。もう一度やり直してください。",
    canNotSelectOnlyOneline: "挿絵の範囲は2段落以上で指定してください",
    limitPlatesForChapter:
      "1つの章に追加できる挿絵の上限（30枚）に達しました。",
    limitPlatesForNovel: "1つの巻に追加できる挿絵の上限（50枚）に達しました。",
    limitWordCountForTieupChapter:
      "章の本文は10,000文字以内で入力してください。",
    // limitDataSizeForTieupChapter:
    //   "段落および文字の数が多すぎます。リロード後、再度本文の量を減らして試してください",
    limitDataSizeForTieupChapter:
      "段落および文字の数が多すぎます。本文の量を減らして、再度試してください",
    emptyTitle: "章のタイトルを入力してください。",
    limitTitleLength: "章のタイトルは50文字以内で入力してください。",
    canNotEditPlateTagsForNovelist:
      '小説家は、挿絵タグ（[image id="xxx"]と[/image]）と、それをまたぐ範囲で文章の編集はできません。',
    selectDrafError: "挿絵挿入を開始したい、1つの段落を選んでください。",
  },
  prompt: {
    inputBody: "本文を入力してください。",
    inputTitle: "例：第一話　物語の始まり",
    selectStartTagPC:
      "挿絵挿入を開始する段落を1つ選んでクリックしてください。（※ドラッグは不可）",
    selectStartTagSP:
      "挿絵挿入を開始する段落を1つ選んでタップしてください。（※ドラッグは不可）",
    selectEndTagPC: "挿絵挿入を終了する段落を1つ選んでクリックしてください。",
    selectEndTagSP: "挿絵挿入を終了する段落を1つ選んでタップしてください。",
    selectRubyText: "ルビを振りたい本文中の単語を選択してください。",
  },
}
