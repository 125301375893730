import { useState, useCallback } from "react"
import routes from "routes"
import { useRouter } from "next/router"

type Props = {
  isLoggedIn?: boolean
}

export const HeaderSearch: React.FC<Props> = ({ isLoggedIn = true }: Props) => {
  const router = useRouter()
  const [isOpened, setIsOpened] = useState(false)
  const [searchText, setSearchText] = useState("")

  const onChangeSearchText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value)
    },
    [],
  )

  const onSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      router.push({
        pathname: routes.tieups(),
        query: searchText
          ? {
              q: searchText,
            }
          : undefined,
      })
    },
    [router, searchText],
  )

  const onClickTrigger = useCallback(() => {
    setIsOpened(true)
  }, [])

  const onClickClose = useCallback(() => {
    setIsOpened(false)
  }, [])

  return (
    <>
      <div className={`global-header-search  ${isOpened && "-opened"}`}>
        <form className={`global-header-search-form`} onSubmit={onSubmit}>
          <input
            className="global-header-search-form-input"
            type="text"
            placeholder="読みたい作品名・ジャンル"
            value={searchText}
            onChange={onChangeSearchText}
          />
          <button
            className="global-header-search-form-submit"
            type="submit"
            aria-label="検索する"
          >
            <i className="fas fa-search global-header-search-form-search-icon" />
          </button>
        </form>
        <button
          className="global-header-search-form-close"
          onClick={onClickClose}
          aria-label="閉じる"
        >
          <i className="fas fa-times global-header-search-form-close-icon" />
        </button>
      </div>

      <button
        className={`global-header-search-trigger${
          isLoggedIn ? " -isLoggedIn" : ""
        }`}
        aria-label="検索窓を開く"
        onClick={onClickTrigger}
      >
        <i className="global-header-search-trigger-icon fas fa-search" />
      </button>
    </>
  )
}
